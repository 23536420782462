<template>
    <v-navigation-drawer
        v-model="active"
        right
        fixed
        temporary
        width="600"
    >
        <v-container v-if="active" class="px-6">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn small text class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline">Configuración</span>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-text-field
                        label="Nombre"
                        v-model="name"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green" dark block @click="onConfirmSelected" :loading="isLoading">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    export default {
        components: { },

        props: {
            account: {
                type: Object,
                default: null
            },

            value: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                active: false,
                name: '',

                isLoading: false,
                dialog: false
            };
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            },

            account(val) {
                this.name = val.name;
            }
        },

        methods: {
            onConfirmSelected(value) {
                this.$store.dispatch('cashAccounts/patch', {
                    account: this.account,
                    data: {
                        name: this.name
                    }
                }).then(() => {
                    this.$emit('update:active', false);
                });
            },

            onBackSelected(value) {
                this.$emit('update:active', false);
            }
        }
    };
</script>
