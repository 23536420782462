<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn icon class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline">Crear Caja</span>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <validation-observer ref="form">
                    <v-form ref="form" v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <validation-provider name="email" v-slot="{ errors }" rules="required">
                                        <v-text-field
                                            v-model="data.name"
                                            label="Nombre"
                                            :error-messages="errors"
                                        />
                                    </validation-provider>
                                </v-col>

                                <v-col cols="12" sm="12">
                                    <validation-provider name="firstName" v-slot="{ errors }" rules="required">
                                        <employee-select
                                            v-model="data.employee"
                                            label="Responsable"
                                            :error-messages="errors"
                                        />
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </validation-observer>

                <v-spacer></v-spacer>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green" dark block @click="onSaveSelected" :loading="isSaving">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import EmployeeSelect from '@/components/Employee/EmployeeSelect';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        components: {
            EmployeeSelect,
            ValidationProvider,
            ValidationObserver
        },

        props: {
            value: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                active: false,
                valid: false,
                showSuccessMessage: true,

                data: {
                    name: '',
                    employee: null
                }
            };
        },

        computed: {
            isSaving() {
                return this.$store.getters['employees/isSaving']();
            },

            employees() {
                return this.$store.getters['employees/getAll']();
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            }
        },

        mounted() {
        },

        methods: {
            async onSaveSelected() {
                let result = await this.$refs.form.validate();
                if(!result) {
                    return;
                }

                try {
                    await this.$store.dispatch('cashAccounts/create', {
                        data: {
                            name: this.data.name,
                            employee: this.data.employee.id
                        }
                    });

                    this.$store.dispatch('app/setStatusMessage', 'Caja creada');

                    this.active = false;
                }
                catch (err) {
                    let formErrors = {};

                    for(let errField in err.errors) {
                        formErrors[errField] = this.$t(err.errors[errField]);
                    }

                    this.$refs.form.setErrors(formErrors);
                }
            },

            onBackSelected() {
                this.active = false;
            }
        }
    };
</script>
