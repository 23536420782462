var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('template-layout',[_c('template',{slot:"content"},[(_vm.canCreate)?_c('cash-account-create-drawer',{model:{value:(_vm.showCreateDrawer),callback:function ($$v) {_vm.showCreateDrawer=$$v},expression:"showCreateDrawer"}}):_vm._e(),_c('cash-account-edit-drawer',{attrs:{"account":_vm.selectedAccount,"active":_vm.showConfig},on:{"update:active":function($event){_vm.showConfig=$event}},model:{value:(_vm.showConfig),callback:function ($$v) {_vm.showConfig=$$v},expression:"showConfig"}}),_c('cash-account-transfer-form',{model:{value:(_vm.showTransfer),callback:function ($$v) {_vm.showTransfer=$$v},expression:"showTransfer"}}),_c('v-container',[_c('v-toolbar',{staticClass:"mb-2",attrs:{"flat":"","dense":"","color":"transparent","height":"60"}},[_c('v-toolbar-title',[_c('span',{staticClass:"headline"},[_vm._v("Cajas")])]),_c('v-spacer'),_c('v-toolbar-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":!_vm.canCreate},on:{"click":function($event){_vm.showCreateDrawer = true}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Crear Cuenta")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":!_vm.canTransfer},on:{"click":function($event){_vm.showTransfer = true}}},on),[_c('v-icon',[_vm._v("mdi-swap-horizontal")])],1)]}}])},[_c('span',[_vm._v("Transferir")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"hide-default-footer":"","hide-default-header":"","headers":_vm.headers,"loading":_vm.isLoading,"items":_vm.accounts,"items-per-page":-1},on:{"click:row":_vm.onAccountSelected},scopedSlots:_vm._u([{key:"item.balance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.balance))+" ")]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-account")]),(item.owner)?_c('span',[_vm._v(_vm._s(item.owner.name))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"end"}},[(_vm.canEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onConfigSelected(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Configuración")])]):_vm._e()],1)]}}])})],1)],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }