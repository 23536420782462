<template>
    <v-autocomplete
        label="Empleado"
        :loading="isLoading"
        v-model="selectedEmployee"
        :items="employees"
        item-text="fullName"
        return-object
        required
    >
    </v-autocomplete>
</template>

<script>
    export default {
        components: {},

        props: {
            value: Object,
            readonly: Boolean
        },

        data() {
            return {
                selectedEmployee: null
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['employees/isLoading']();
            },

            employees() {
                return this.$store.getters['employees/getAll']();
            }
        },

        watch: {
            selectedEmployee(v) {
                this.$emit('input', v);
            },

            value(v) {
                this.selectedEmployee = v;
            }
        },

        mounted() {
            this.$store.dispatch('employees/fetchAll');
        },

        methods: {
        }
    };
</script>

<style lang="scss" scoped>

</style>
