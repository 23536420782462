<template>
    <template-layout>
        <template slot="content">
            <cash-account-create-drawer
                v-if="canCreate"
                v-model="showCreateDrawer"
            />

            <cash-account-edit-drawer
                v-model="showConfig"
                :account="selectedAccount"
                :active.sync="showConfig"
            />

            <cash-account-transfer-form
                v-model="showTransfer"
            />

            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Cajas</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon :disabled="!canCreate" @click="showCreateDrawer = true" v-on="on">
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </template>
                            <span>Crear Cuenta</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon :disabled="!canTransfer" @click="showTransfer = true" v-on="on">
                                    <v-icon>mdi-swap-horizontal</v-icon>
                                </v-btn>
                            </template>
                            <span>Transferir</span>
                        </v-tooltip>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <v-data-table
                            hide-default-footer
                            hide-default-header
                            class="elevation-0"
                            :headers="headers"
                            :loading="isLoading"
                            :items="accounts"
                            :items-per-page="-1"
                            @click:row="onAccountSelected"
                        >
                            <template v-slot:item.balance="{ item }">
                                {{ item.balance | toCurrency }}
                            </template>

                            <template v-slot:item.owner="{ item }">
                                <v-icon>mdi-account</v-icon><span v-if="item.owner">{{ item.owner.name }}</span>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-row justify="end">
                                    <v-tooltip bottom v-if="canEdit">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon @click.stop="onConfigSelected(item)" v-on="on">
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Configuración</span>
                                    </v-tooltip>
                                </v-row>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout.vue';
    import CashAccountTransferForm from './CashAccountTransferForm';
    import CashAccountCreateDrawer from './CashAccountCreateDrawer';
    import CashAccountEditDrawer from './CashAccountEditDrawer';

    export default {
        components: {
            TemplateLayout,
            CashAccountCreateDrawer,
            CashAccountEditDrawer,
            CashAccountTransferForm
        },

        data() {
            return {
                showConfig: false,
                showTransfer: false,
                showCreateDrawer: false,
                selectedAccount: null,

                headers: [
                    { text: 'Nombre', value: 'name', sortable: false },
                    { text: 'Responsable', value: 'owner', sortable: false },
                    { text: 'Balance', value: 'balance', sortable: false },
                    { text: '', value: 'actions', sortable: false }
                ]
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['cashAccounts/isLoading']();
            },

            accounts() {
                let accounts = this.$store.getters['cashAccounts/getAll']();
                let isAdmin = this.$store.getters['user/hasRole']('ROLE_ADMIN');

                if(isAdmin) {
                    return accounts;
                }
                else {
                    return accounts.filter(ca => ca.owner.id === this.$store.getters['user/get'].id);
                }
            },

            canEdit() {
                return this.$store.getters['user/hasRole']('ROLE_ADMIN');
            },

            canCreate() {
                return this.$store.getters['user/hasRole']('ROLE_ADMIN');
            },

            canTransfer() {
                return this.$store.getters['user/hasRole']('ROLE_ADMIN') || this.$store.getters['user/hasRole']('ROLE_CHOFER');
            }
        },

        watch: {
            showConfig(val) {
                if(val) {
                    this.showTransfer = false;
                }
            },

            showTransfer(val) {
                if(val) {
                    this.showConfig = false;
                }
            }
        },

        mounted() {
            this.$store.dispatch('cashAccounts/fetchAll');
        },

        methods: {
            onAccountSelected(account) {
                this.$router.push({ name: 'cashAccountMovementsList', params: { id: account.id } });
            },

            onConfigSelected(account) {
                this.selectedAccount = account;
                this.showConfig = true;
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
