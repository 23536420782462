<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10">
            <v-row>
                <v-col>
                    <v-row class="align-center">
                        <v-btn small text class="black--text" @click="onBackSelected">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <span class="headline">Transferir efectivo</span>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <validation-observer ref="form">
                    <v-form ref="form" v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-select
                                        label="Caja Origen"
                                        v-model="source"
                                        :items="sourceAccounts"
                                        item-text="name"
                                        item-value="id"
                                        return-object
                                        required
                                        :disabled="sourceAccounts && sourceAccounts.length === 1"
                                    >
                                        <template v-slot:selection="data">
                                            {{ data.item.name }} - {{ data.item.owner.name }}
                                        </template>

                                        <template v-slot:item="data">
                                            {{ data.item.name }} - {{ data.item.owner.name }}
                                        </template>
                                    </v-select>
                                </v-col>

                                <v-col cols="12">
                                    <v-select
                                        label="Caja Destino"
                                        v-model="destination"
                                        :items="destinationAccounts"
                                        item-text="name"
                                        item-value="id"
                                        return-object
                                        required
                                    >
                                        <template v-slot:selection="data">
                                            {{ data.item.name }} - {{ data.item.owner.name }}
                                        </template>

                                        <template v-slot:item="data">
                                            {{ data.item.name }} - {{ data.item.owner.name }}
                                        </template>
                                    </v-select>
                                </v-col>

                                <v-col cols="12">
                                    <validation-provider name="amount" v-slot="{ errors }" rules="required">
                                        <v-currency-field
                                            label="Monto"
                                            v-model="amount"
                                            :hint="balance | toCurrency"
                                            :persistent-hint="true"
                                            prefix="$"
                                            :disabled="!source || !destination"
                                            :error-messages="errors"
                                        ></v-currency-field>
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </validation-observer>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green" dark block @click="onConfirmSelected" :loading="isLoading">Transferir</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },

        props: {
            account: {
                type: Object,
                default: null
            },

            value: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                active: false,

                amount: 0,
                source: null,
                destination: null
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['cashAccounts/isLoading']();
            },

            sourceAccounts() {
                let accounts = this.$store.getters['cashAccounts/getAll']();
                let isAdmin = this.$store.getters['user/hasRole']('ROLE_ADMIN');

                if(isAdmin) {
                    return accounts;
                }
                else {
                    return accounts.filter(ca => ca.owner.id === this.$store.getters['user/get'].id);
                }
            },

            destinationAccounts() {
                let sourceAccounts = this.$store.getters['cashAccounts/getAll']();
                return sourceAccounts.filter(account => {
                    return account !== this.source;
                });
            },

            balance() {
                if(!this.source) {
                    return 0;
                }

                return this.source.balance;
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            },

            sourceAccounts(val) {
                if(val && val.length === 1) {
                    this.source = val[0];
                }
            }
        },

        methods: {
            async onConfirmSelected(value) {
                try {
                    await this.$store.dispatch('cashAccounts/transfer', { source: this.source, destination: this.destination, amount: parseFloat(this.amount) });

                    this.$store.dispatch('app/setStatusMessage', 'Transferencia registrada');

                    this.active = false;
                    this.amount = 0;
                    this.source = null;
                    this.destination = null;
                }
                catch (err) {
                    let formErrors = {};

                    for(let errField in err.errors) {
                        formErrors[errField] = this.$t(err.errors[errField]);
                    }

                    this.$refs.form.setErrors(formErrors);
                }
            },

            onBackSelected(value) {
                this.$emit('update:active', false);

                this.amount = 0;
                this.source = null;
                this.destination = null;
            }
        }
    };
</script>
